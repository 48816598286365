import { GoogleAdsId, CriteoPartnerID, FacebookId } from '../../utils/GoogleAds'

const toCamelCase = (name) => name
    .replace(/[^a-z0-9]/gi, ' ')
    .split(' ')
    .reduce((t, c, i) => {
      return t + (i > 0 ? c[0].toUpperCase() + c.substring(1) : c)
    })

const phoneToE164 = (phoneNumber) => {
  phoneNumber = phoneNumber.replace(/[^+\d]/g, '')
  // If the phone number starts with '+', assume it's already in E.164 format
  if (phoneNumber.startsWith('+')) {
    return phoneNumber
  }
  // If the phone number doesn't start with '+', add the default country code (+1)
  if (!phoneNumber.startsWith('+')) {
    return `+1${phoneNumber}`
  }
  return phoneNumber
}

const TrackUser = (email, props) => {
  if ('requestIdleCallback' in window) {
    requestIdleCallback(() => RunTrackUser(email, props))
  } else {
    setTimeout(() => {
      RunTrackUser(email, props)
    }, 50) // Fallback if `requestIdleCallback` isn't supported
  }
}
const CheckTrackedUser = () => {
  if ('requestIdleCallback' in window) {
    requestIdleCallback(() => RunCheckTrackedUser())
  } else {
    setTimeout(() => {
      RunCheckTrackedUser()
    }, 50) // Fallback if `requestIdleCallback` isn't supported
  }
}
const RunCheckTrackedUser = () => {
  const userData = localStorage.getItem('state')
  if (userData === null) {
    return
  }
  const user = JSON.parse(userData)
  if(user.email && user.details) {
    RunTrackUser(user.email, user.details, false)
  }
}

const SendCriteoEvent = (event) => {
  window.criteo_q.push(
    { event: 'setAccount', account: CriteoPartnerID },
    { event: 'setEmail', email: window?.user?.email || '', hash_method: 'none' },
    { event: 'setSiteType', type: window._device_type },
    { event: 'setZipcode', email: (window?.user?.address || window?.user?.billing || {}).postalCode  || '' },
    event
  )
}

const RunTrackUser = (email, props, save=true) => {
  if(typeof window === 'undefined') return 
  //
  if(save) {
    // update user, save to localStorage
    let user = window.user || {} 
    user.email = email
    user.details = user.details ? { ...user.details, ...props } : props
    localStorage.setItem('user', JSON.stringify(user))
    window.user = user
  }
  const phoneE164 = phoneToE164(props.phone)
  window._learnq.push(['identify', {
    '$email': email,
    'Name': props.fullName || props.full_name || props.name,
  }])
  const address = props.address || props.billing || {}
  window.gtag('set', 'user_data', {
    email: email,
    phone_number: phoneE164,
    address: {
      first_name: props.firstName || props.name,
      last_name: props.lastName,
      street: address.street,
      suit: address.suit,
      city: address.city,
      region: address.state,
      postal_code: address.postalCode,
      country: address.country || 'US',
    },
  })
  window.uetq.push('set', {
    pid: {
      em: email,
      ph: phoneE164,
    },
  })
  window.fbq('init', FacebookId, {
    em: email,
    fn: props.firstName || props.name,
    ln: props.lastName,
    ph: phoneE164,
    ct: (address?.city||'').toLowerCase().replace(/\s/g, ''),
    st: (address?.state||'').toLowerCase().replace(/\s/g, ''),
    country: address?.country || 'US',
    zp: address?.postalCode,
  })
  // window.criteo_q.push( { event: 'setEmail', email, hash_method: 'none' })
  // if(address.postalCode) {
  //   window.criteo_q.push({ event: 'setZipcode', zipcode: address.postalCode })
  // }
}

const TrackUniqueEvent = ({ event, id: unique_key }, properties, options = {}) => {
  const cacheKey = 'eventCache'
  let sessionEventCache = sessionStorage.getItem(cacheKey)
    ? JSON.parse(sessionStorage.getItem(cacheKey))
    : {}
  const keyDetails = unique_key ? unique_key : properties
  const eventKey = `${event}:${JSON.stringify(keyDetails)}`

  if (sessionEventCache[eventKey]) {
    return // Exit if duplicate
  }

  // Update cache only if not a duplicate
  sessionEventCache[eventKey] = true
  sessionStorage.setItem(cacheKey, JSON.stringify(sessionEventCache))

  TrackEvent(event, properties, options)
}

const sendConvertedGoogleAdsToBingAds = (event, googleAds) => {
  const { value, ...properties } = googleAds
  const props = { event_value: value }
  if(event === 'purchase') {
    props.revenue_value = properties.revenue_value || value || 0
  }
  window.uetq.push('event', event, properties)
}
const TrackEvent = (event, properties, options) => {
  if ('requestIdleCallback' in window) {
    requestIdleCallback(() => RunTrackEvent(event, properties, options))
  } else {
    setTimeout(() => {
      RunTrackEvent(event, properties, options)
    }, 50) // Fallback if `requestIdleCallback` isn't supported
  }
}

const PrepareKlaviyoEventData = (eventName, klaviyoEventData, properties) => {
  klaviyoEventData = klaviyoEventData || properties
  if(!klaviyoEventData) return {}
  if (klaviyoEventData['value'] && !klaviyoEventData['$value']) {
    klaviyoEventData['$value'] = klaviyoEventData['value']
  }
  if (klaviyoEventData['currency'] && !klaviyoEventData['Currency Code']) {
    klaviyoEventData['Currency Code'] = klaviyoEventData['currency']
  }
  return klaviyoEventData
}

const RunTrackEvent = (event, properties, { googleAdsConversion, criteo, klaviyoEventName, klaviyoEvent } = {}) => {
  if (typeof window === 'undefined') return
  // Event Tracking Logic
  // ** Google Ads / Bing Ads/ Criteo - use lower_snake_case event names
  // ** Google Analytics, Klaviyo - use "Title Case", user friendly event names
  // window.dataLayer.push(googleAds)
  window._learnq.push(['track', klaviyoEventName || event, PrepareKlaviyoEventData(klaviyoEventName || event, klaviyoEvent, properties)])
  window.gtag('event', event, properties)
  window.fbq('trackCustom', event, properties)
  sendConvertedGoogleAdsToBingAds(event, properties)
  if(googleAdsConversion) {
    window.gtag('event', 'conversion', {
      send_to: `${GoogleAdsId}/${googleAdsConversion}`,
      ...properties
    })
  }
  if(criteo) {
    SendCriteoEvent(criteo)
    // window.criteo_q.push(criteo)
  }
  // if (window.rudderanalytics) {
  //   window.rudderanalytics.track(event, properties)
  // }
}

/*
 RudderStack defined identity names
 - firstName
 - lastName
 - name
 - age
 - email
 - phone
 - address [object]
 - city
 - country
 - postalCode
 - state
 - street
 - birthday
 - company [object]
 - name
 - id
 - industry
 - employee_count
 - plan
 - createdAt
 - description
 - gender
 - title
 - username
 - website
 - avatar
 */


const PersonalInfo = (form) => {
  const personal = {}
  const joinString = (s1, s2) => {
    if (!s1) return s2
    if (!s2) return s1
    return s1 + ' ' + s2
  }
  const extendAddress = (key, value, billing = false) => {
    const address = billing ? 'billing_address' : 'address'
    personal[address] = personal[address] || {}
    personal[address][key] = joinString(personal[address][key], value)
  }

  Object.keys(form).forEach(key => {
    if (!Object.prototype.hasOwnProperty.call(form, key)) return
    if (!key.match(/name|phone|mobile|number|email|e-mail|address|zip|postal|city|state|country/gi)) return
    const isBillingAddress = key.toLowerCase().startsWith('billing')
    const value = form[key]
    if(key.match(/formname/gi)) {
      // ignore
    } else if (key.match(/name/gi)) {
      if (key.match(/user/gi)) {
        personal['username'] = value
      } else if (key.match(/shipping_name|first|given|fname/gi)) {
        personal['firstName'] = value
      } else if (key.match(/last|family|lname|surname/gi)) {
        personal['lastName'] = value
      } else {
        personal['name'] = value
        personal[toCamelCase(key)] = value
      }
    } else if (key.match(/email|e-mail/gi)) {
      personal['email'] = value
    } else if (key.match(/address[_ ]?2/gi)) {
      extendAddress('suit', value, isBillingAddress)
    } else if (key.match(/address/gi)) {
      extendAddress('street', value, isBillingAddress)
    } else if (key.match(/state/gi)) {
      extendAddress('state', value, isBillingAddress)
    } else if (key.match(/zip|postal/gi)) {
      extendAddress('postalCode', value, isBillingAddress)
    } else if (key.match(/country/gi)) {
      extendAddress('country', value, isBillingAddress)
    } else if (key.match(/city/gi)) {
      extendAddress('city', value, isBillingAddress)
    } else if (key.match(/phone|mobile/gi)) {
      extendAddress('phone', value, isBillingAddress)
      personal['phone'] = value
    } else {
      personal[toCamelCase(key)] = value
    }
  })
  if (personal.firstName || personal.lastName) {
    if (!personal.name) {
      personal.name = `${personal.firstName} ${personal.lastName}`.trim()
    }
  }
  return personal
}

export { TrackUser, TrackEvent, TrackUniqueEvent,  PersonalInfo, phoneToE164, CheckTrackedUser, SendCriteoEvent }
